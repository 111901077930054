import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { onClose } from '../../utils';
import { MixpanelHelpers, MIXPANEL_ACTION_BACK } from '../../utils/mixpanel';
import robustStorage from '../../utils/robust-local-storage';

type Props = {
  hasCornerBackButton: ?boolean,
  hasCornerCloseButton: ?boolean,
};

const FrameNavigation = ({
  hasCornerBackButton,
  hasCornerCloseButton,
}: Props) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const handleBack = () => {
    // When the Intro screen is shown the first time, it saves these values in localStorage
    // so the next time the consumer uses Checkout then it will not be shown again.
    // If the consumer wants to see the Intro screen, the values need to be deleted from localStorage.
    if (pathname === '/login') {
      robustStorage.setItem('paidy_seen_classic_intro', 'false');
      robustStorage.setItem('paidy_seen_plus_intro', 'false');
    }

    // If Remember Me is enabled, the Login screen is skipped (auto-login).
    // If the consumer wants to go back to the Login screen, Remember Me should be disabled.
    if (pathname === '/otp') {
      robustStorage.setItem('paidy_remember_me', 'false');
    }

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_BACK,
    });

    history.goBack();
  }

  return (
    <nav>
      <div>
        {hasCornerBackButton && (
          <button id="btn-back" onClick={handleBack}>
            <img
              src={require('../../assets/icon-back-gray.svg')}
              alt="navigate to previous screen"
            />
          </button>
        )}
      </div>
      <div>
        {pathname !== '/' ? <img src={require('../../assets/paidy-logo.svg')} alt="paidy logo" /> : null}
      </div>
      <div>
        {hasCornerCloseButton && (
          <button id="btn-close" onClick={() => onClose(!pathname.includes('/result') && !pathname.includes('/error'))}>
            <img src={require('../../assets/icon-close-gray.svg')} alt="close Paidy Checkout" />
          </button>
        )}
      </div>
    </nav>
  );
};

export default FrameNavigation;
