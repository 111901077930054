import React, { useState, useEffect } from 'react';
import idx from 'idx';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { updatePaymentPairs } from '../../redux/modules/payment';
import { paymentStatusMap, SELECTION_OPTION_PHASE } from '../../constants';

import Frame from '../PlusUpgradeFrame/FrameContainer';
import PlusUpgradeSucessModal from './PlusUpgradeSuccessModal';
import PlusUpgradeLoader from './PlusUpgradeLoader';
import PlusUpgradeMoreThanTwoMinutes from './PlusUpgradeMoreThanTwoMinutes';
import PlusUpgradeWaitingScreen from './PlusUpgradeWaitingScreen';

import { errorHandler, isWithinCICBusinessHours } from '../../utils';

import type { ThreePayOption, InstallmentPlanOptions } from '../ThreePay/type';

import styles from './PlusUpgradePending.scss';

const PlusUpgradePending = (props: { onFetchStatus: Function }) => {
  const dispatch = useDispatch();

  const MAX_WAITING_TIME = useSelector(state => state.payment.plusUpgradeWaitingTime) || 0;
  const TIME_INTERVAL = 60000; // 60000 milliseconds / 1 minute;
  const LOADING_TO_VIDEO_DURATION = 1000; // 1 second
  const SEVEN_MINUTES_ELAPSED_TIME = 7; // 7 minutes

  const history = useHistory();
  const [pendingState, setPendingState] = useState(0);
  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  const [isApplicationSuccessful, setIsApplicationSuccessful] = useState(false);

  const selectedOption: ThreePayOption = useSelector(state => state.payment.selectedOption);
  const installmentPlanOptions: InstallmentPlanOptions = useSelector(
    state => state.payment.installmentPlanOptions
  );
  const availableOptions: ThreePayOption[] = installmentPlanOptions.available;

  const fetchPaymentStatus = () => {
    props.onFetchStatus().then(
      res => {
        const authStatus = idx(res, _ => _.data.status);
        if (authStatus === paymentStatusMap.AUTHENTICATED) {
          setElapsedMinutes(-1);
          setIsApplicationSuccessful(true);
        } else if (authStatus === paymentStatusMap.CANCELED) {
          setElapsedMinutes(-1);
          history.push('/error/plus-upgrade.kyc-rejected');
        }
      },
      err => {
        console.log('err::', err);
        errorHandler({ err, history });
      }
    );
  };

  const onHandleElapsedTime = () => {
    if (elapsedMinutes === SEVEN_MINUTES_ELAPSED_TIME) {
      // More than 7 minutes
      setPendingState(2);
    } else if (Date.now() >= MAX_WAITING_TIME) {
      setTimeout(() => {
        dispatch(updatePaymentPairs({ status: 'failed' }));
        // More than 30 minutes
        if (isWithinCICBusinessHours()) {
          history.push('/error/plus-upgrade.timeout');
        } else {
          history.push('/error/plus-upgrade.outside-cic-hours');
        }
      }, 1000);
    }
  };

  useEffect(() => {
    // Called every minute
    const timeout = setTimeout(() => {
      setElapsedMinutes(elapsedMinutes => elapsedMinutes + 1);
      fetchPaymentStatus();
    }, TIME_INTERVAL);

    // Clear timeout if elapsedMinutes is greater than or equal to MAX_TIME
    if (Date.now() >= MAX_WAITING_TIME || elapsedMinutes === -1) {
      clearTimeout(timeout);
    }

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timeout);
  }, [elapsedMinutes]);

  useEffect(() => onHandleElapsedTime(), [elapsedMinutes]);

  useEffect(() => {
    setTimeout(() => setPendingState(1), LOADING_TO_VIDEO_DURATION);
  }, []);

  const renderByState = () => {
    switch (pendingState) {
      case 0:
        return <PlusUpgradeLoader />;
      case 1:
        return <PlusUpgradeWaitingScreen />;
      case 2:
        return <PlusUpgradeMoreThanTwoMinutes />;
      default:
        return null;
    }
  };

  return (
    <>
      <PlusUpgradeSucessModal
        visible={isApplicationSuccessful}
        onClose={() => {
          setIsApplicationSuccessful(false);
          const found = availableOptions.find(option => option.kind === selectedOption.kind);
          if (!found) {
            // Removed selected option and navigate back to /3pay/select
            dispatch(
              updatePaymentPairs({
                selectedOption: undefined,
                selectionOptionPhase: SELECTION_OPTION_PHASE.UNAVAILABLE,
              })
            );
            history.push('/3pay/select');
          } else {
            history.push('/3pay/confirm');
          }
        }}
        currentPendingState={pendingState}
      />
      <Frame hasCornerBackButton={false} hasFooter={false} helpType="plusUpgrade">
        {renderByState()}
      </Frame>
    </>
  );
};

export default PlusUpgradePending;
