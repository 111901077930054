// @flow
import autobind from 'autobind-decorator';
import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import idx from 'idx';
import { withRouter } from 'react-router-dom';

import Frame from '../FrameDemo/FrameContainer';
import keyMap from '../../../constants/key-map';
import keyCodeMap from '../../../constants/key-code';
import {
  getTierAndTypeFromAuthResponse,
  setValueByIndex,
  errorHandler,
  zenToHan,
} from '../../../utils';
import { hasEventKey } from '../../../utils/key';
import getPhoneToDisplay from '../../../utils/get-phone-to-display';
import {
  tierMap,
  paymentStatusMap,
  installmentTypeMap,
  authTypeMap,
  requiredBigContractDataTypeMap,
} from '../../../constants';
import { authTypes } from '../../../types';
import styles from './OTPInput.scss';
import smsStyles from './SMSPrompt.scss';
import {
  MixpanelHelpers,
  MIXPANEL_ACTION_AUTHORIZATION_SUCCESS,
  MIXPANEL_ACTION_AUTHORIZATION_FAILURE,
  MIXPANEL_ACTION_FIELD_EDIT,
  MIXPANEL_ACTION_LINK_CLICK,
  MIXPANEL_ACTION_PAGE_VIEW,
  AUTH_TYPE_DISPLAY_NAMES,
} from '../../../utils/mixpanel';
import robustStorage from "../../../utils/robust-local-storage";

const PINCODE_DEBOUNCE = 15; // seconds
const ERROR_WRONG_PINCODE: Array<string> = [
  'payment.authentication.failed',
  'token.authentication.failed',
];

declare var document: {
  ...Document,
  msHidden: string,
  webkitHidden: string,
  addEventListener: Function,
};

type State = {
  otp: Array<string>,
  status: ?string,
  isAuthorized: boolean,
  authType: authTypes,
  isWrongPincode: boolean,
  showVoicePrompt: boolean,
  showMainPrompt: boolean,
  waiting: number,
  contentWidth: number,
};

type Props = {
  email: ?string,
  phone: ?string,
  digits: number,
  paymentId: ?string,
  onAuthenticate: Function,
  history: any,
  location: Object,
  onGetCodeThruVoice: Function,
  isToken: boolean,
  onCreate: Function,
};

let hasPinCodeBeenEdited = false;

class OTPInputDemo extends Component<Props, State> {
  _isMounted: ?boolean;
  inputsWrapper: HTMLElement;
  inputs: Array<?HTMLInputElement> = [];
  isInputsInVisibleArea: boolean = false;
  otpInitialState: Array<string> = new Array(this.props.digits).fill('');
  resizeObserver: ResizeObserver | null = null;
  containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: State = {
    otp: this.otpInitialState,
    status: null,
    isAuthorized: false,
    authType: authTypeMap.SMS,
    isWrongPincode: false,
    showVoicePrompt: false,
    showMainPrompt: true,
    waiting: 0,
    contentWidth: 0,
  };

  componentDidMount() {
    const { pathname } = this.props.location;

    this._isMounted = true;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
      },
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
      },
    });

    this.resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) return;
      if (!entries.length) return;

      const entry = entries[0];
      this.setState({ contentWidth: entry.contentRect.width });
    });

    if (this.containerRef.current) {
      this.resizeObserver.observe(this.containerRef.current);
    }

    /**
     * if [this.props.paymentId] doesn't exist
     * meaning this is a returning user using cached credentials
     * create the payment immediately as soon as this component is mounted
     */
    if (!this.props.paymentId) {
      this.onCreate();
    }

    setTimeout(() => {
      if (this._isMounted) {
        this.setState({
          showVoicePrompt: true,
        });
      }
    }, 5000);
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;

    this._isMounted = false;

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
      extraData: {
        'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
      },
    });

    if (this.resizeObserver && this.containerRef.current) {
      this.resizeObserver.unobserve(this.containerRef.current);
    }
  }

  @autobind
  onCreate() {
    const { email, phone, onCreate, history } = this.props;

    // returning user
    if (email && phone) {
      onCreate(email, phone)
        .then(res => {
          console.log('Payment created. Response:', res);
        })
        .catch(err => {
          errorHandler({ err, history });
        });
    }
  }

  @autobind
  onFocus(event: SyntheticEvent<HTMLInputElement>): void {
    event.preventDefault();

    if (this.state.isWrongPincode) {
      this.setState({
        isWrongPincode: false,
      });
    }
  }

  @autobind
  onNumberInput(event: SyntheticInputEvent<HTMLInputElement>): void {
    const target = event.currentTarget;
    const convertedValue = zenToHan(target.value || '');

    // numbers only
    // accepts two characters for replacement bahavior
    if (!/^\d\d?$/.test(convertedValue)) {
      return;
    }

    const { pathname } = this.props.location;
    const { otp } = this.state;
    const _idx: number = +target.getAttribute('data-order');
    const pinCode = convertedValue.replace(otp[_idx], '');

    if (!hasPinCodeBeenEdited) {
      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FIELD_EDIT,
        actionItem: 'Pin Code',
        extraData: {
          'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
        },
      });

      hasPinCodeBeenEdited = true;
    }

    if (target instanceof HTMLInputElement) {
      this.setState(
        state => ({
          otp: setValueByIndex(_idx, pinCode, state.otp),
        }),
        () => {
          const nextInputIdx = +_idx + 1;

          if (this.state.otp.join('').trim().length >= 4) {
            this.onSubmit();
          }

          if (this.inputs[nextInputIdx]) {
            this.inputs[nextInputIdx].focus();
          }
        }
      );
    }
  }

  @autobind
  onKeyDown(event: SyntheticKeyboardEvent<HTMLInputElement>): void {
    let key;
    let chosenKeyMap;

    if (!hasEventKey(event)) {
      key = event.keyCode;
      chosenKeyMap = keyCodeMap;
    } else {
      key = event.key;
      chosenKeyMap = keyMap;
    }

    const _idx: number = +event.currentTarget.getAttribute('data-order');

    switch (key) {
      case chosenKeyMap.E: {
        event.preventDefault();

        break;
      }
      case chosenKeyMap.PLUS: {
        event.preventDefault();

        break;
      }
      case chosenKeyMap.MINUS: {
        event.preventDefault();

        break;
      }
      case chosenKeyMap.ENTER: {
        // if the user hit enter on the last input
        if (+event.currentTarget.getAttribute('data-order') === +this.props.digits - 1) {
          this.onSubmit();
        }

        break;
      }
      case chosenKeyMap.BACKSPACE:
      case chosenKeyMap.DELETE: {
        if (event.currentTarget.value === '' && this.inputs[+_idx - 1]) {
          this.setState(
            state => ({
              otp: setValueByIndex(+_idx - 1, '', state.otp),
            }),
            () => {
              // $FlowFixMe
              this.inputs[+_idx - 1].focus();
            }
          );
        } else {
          this.setState(state => ({
            otp: setValueByIndex(_idx, '', state.otp),
          }));
        }

        break;
      }
      case chosenKeyMap.LEFT_ARROW: {
        if (this.inputs[+_idx - 1]) {
          // $FlowFixMe
          this.inputs[+_idx - 1].focus();
        }

        break;
      }
      case chosenKeyMap.RIGHT_ARROW: {
        if (this.inputs[+_idx + 1]) {
          // $FlowFixMe
          this.inputs[+_idx + 1].focus();
        }

        break;
      }
      default: {
        break;
      }
    }
  }

  @autobind
  authenticate(): Promise<any> {
    const { isAuthorized, otp } = this.state;
    const { pathname } = this.props.location;
    const { history, onAuthenticate, isToken } = this.props;

    if (isAuthorized) {
      return Promise.reject();
    }

    if (otp.join('').length < 4) {
      console.error('Please fill in all four digits.');

      return Promise.reject();
    }

    return onAuthenticate(otp.join('')).then(
      res => {
        const {
          tier,
          installmentType,
          formType,
          authStatus,
          requiredConsumerData,
          requiredBigContractData,
          highTicket,
        } = getTierAndTypeFromAuthResponse(res, isToken);
        const status = idx(res, _ => _.data.status);

        let routeStr: string = '/3pay/select';

        if (highTicket && status === paymentStatusMap.AUTHENTICATED) {
          history.push('/high-ticket');

          return res;
        }

        if (tier === tierMap.CLASSIC && installmentType === installmentTypeMap.SINGLE) {
          routeStr = '/result/single-pay';
        }

        if (installmentType === installmentTypeMap.MULTI) {
          routeStr = '/multi-pay/select';
        }

        MixpanelHelpers.trackAction({
          pathname,
          actionName: MIXPANEL_ACTION_AUTHORIZATION_SUCCESS,
          extraData: {
            'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
          },
        });

        /**
         * Digital
         */
        if (tier === tierMap.DIGITAL) {
          if (authStatus === paymentStatusMap.AUTHORIZED) {
            /**
             * this user has been authorized with digital payment before
             */
            history.push('/result/digital');
          } else if (authStatus === paymentStatusMap.AUTHENTICATED) {
            if (installmentType === installmentTypeMap.MULTI) {
              history.push('/multi-pay/select');
            } else if (requiredConsumerData !== null) {
              /**
               * requiredConsumerData will look like { address: null, name1: null }
               * if the values aren't null, prefill them to the form
               */
              history.push('/digital/form');
            } else if (installmentType === installmentTypeMap.THREEPAY) {
              history.push('/3pay/select');
            }
          } else {
            history.push('/result/rejected');
          }

          return res;
        }

        /**
         * Token
         */
        if (installmentType === installmentTypeMap.TOKEN) {
          if (authStatus === paymentStatusMap.AUTHENTICATED) {
            history.push(requiredConsumerData ? '/digital/form' : '/token/confirm');
          } else {
            /**
             * TOFIX: make sure what will happen if the authentication fail for Token
             */
            history.push('/error/auth-fail');
          }

          return res;
        }

        /**
         * Big
         */
        if (
          tier === tierMap.CLASSIC &&
          requiredBigContractData === requiredBigContractDataTypeMap.REGULAR
        ) {
          history.push('/big');

          return res;
        }

        /**
         * Plus
         */
        if (tier === tierMap.PLUS && formType) {
          if (status === paymentStatusMap.AUTHENTICATED) {
            routeStr = `/plus/${formType}`;
          } else {
            /**
             * TOFIX: make sure what will happen if the authentication fail for Plus
             */
            routeStr = '/result/rejected';
          }

          history.push(routeStr);

          return res;
        }

        /**
         * Classic
         */
        if (installmentType === installmentTypeMap.SINGLE) {
          switch (status) {
            case paymentStatusMap.AUTHORIZED: {
              routeStr = '/result/single-pay';

              break;
            }
            case paymentStatusMap.REJECTED: {
              routeStr = '/result/rejected';

              break;
            }
            /**
             * since we don't get to do config/payload validation on the frontend side
             * because neither should have double validation on both FE/BE or
             * have a validation API to use (that way we can use their validation on FE end so we can validate
             * on our end), this is for anything that's not properly configured on the merchant side and
             * fall back to here, we show `/error/server-error` for them as a general message
             * while it's not perfect but I guess it's still better than showing them rejected screen
             */
            default: {
              routeStr = '/error/service-error';

              break;
            }
          }
        } else if (installmentType === installmentTypeMap.MULTI) {
          if (res.data.status !== paymentStatusMap.AUTHENTICATED) {
            routeStr = '/error/auth-fail';
          }
        }

        history.push(routeStr);

        return res;
      },
      err => {
        MixpanelHelpers.trackAction({
          pathname,
          actionName: MIXPANEL_ACTION_AUTHORIZATION_FAILURE,
          extraData: {
            'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
          },
        });
        const errorCode = idx(err, _ => _.data.code);

        if (ERROR_WRONG_PINCODE.indexOf(errorCode) > -1) {
          console.error('Wrong pincode.');

          this.setState({
            isWrongPincode: true,
            otp: this.otpInitialState,
          });
        } else {
          errorHandler({ err, history });
        }

        return Promise.reject(JSON.stringify(err));
      }
    );
  }

  @autobind
  onSubmit(event: ?SyntheticEvent<HTMLButtonElement>): void {
    const { pathname } = this.props.location;

    if (event) {
      event.preventDefault();
    }

    MixpanelHelpers.trackAction({
      pathname,
      actionName: 'Submit',
      extraData: {
        'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
      },
    });

    this.authenticate().catch(() => {
      /* Error already handled inside */
    });
  }

  renderInputs(digits: number): Array<React$Node> {
    /* eslint-disable */
    return Array.from({ length: digits }).map((_, i) => (
      <input
        id={`input_pin_${i}`}
        key={`input_pin_${i}`}
        type="tel"
        pattern="\d*"
        className={cx(styles.input, this.state.isWrongPincode && styles.error)}
        onInput={this.onNumberInput}
        onKeyDown={this.onKeyDown}
        name={`input_${i}`}
        data-order={i}
        ref={input => {
          this.inputs[i] = input;
        }}
        value={this.state.otp[i]}
        // this is just to suppress the warning that we don't have an onChange handler
        onChange={() => {}}
        max={9}
        min={0}
        onFocus={this.onFocus}
        autoFocus={i === 0 && this.state.authType === authTypeMap.SMS}
        autoComplete="off"
      />
    ));
    /* eslint-enable */
  }

  @autobind
  switchToVoice(event: SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault();

    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_LINK_CLICK,
      actionItem: 'Voice Pin Code',
      extraData: {
        'Screen Type': AUTH_TYPE_DISPLAY_NAMES[this.state.authType] || this.state.authType,
      },
    });

    this.props.onGetCodeThruVoice().catch((err: Object) => {
      errorHandler({ err, history: this.props.history });
    });

    this.setState(
      {
        authType: authTypeMap.VOICE,
      },
      () => {
        if (this.inputs[0]) {
          this.inputs[0].focus();
        }

        this.waitVoiceCode();
      }
    );
  }

  @autobind
  resendVoicePincode() {
    if (this.state.waiting > 0) {
      return;
    }

    MixpanelHelpers.trackAction({
      customPath: 'Authentication (Payment Authorization)',
      actionName: MIXPANEL_ACTION_LINK_CLICK,
      actionItem: 'Resend Code',
      extraData: {
        'Screen Type': 'Voice',
      },
    });

    this.props.onGetCodeThruVoice().then(() => {
      if (this.inputs[0]) {
        this.inputs[0].focus();
      }

      this.waitVoiceCode();
    });
  }

  @autobind
  waitVoiceCode() {
    this.setState({ waiting: PINCODE_DEBOUNCE });
    setTimeout(this.waitingVoiceCode, 1000);
  }

  @autobind
  waitingVoiceCode() {
    const now = this.state.waiting - 1;

    this.setState({ waiting: now });

    if (now) {
      setTimeout(this.waitingVoiceCode, 1000);
    }
  }

  @autobind
  renderActionLink() {
    switch (this.state.authType) {
      case authTypeMap.SMS: {
        return (
          <a href="#" onClick={this.switchToVoice}>
            自動音声案内で認証コードを受け取る
          </a>
        );
      }
      case authTypeMap.VOICE: {
        const isWaiting = this.state.waiting > 0;

        return (
          <a href="#" onClick={this.resendVoicePincode} disabled={isWaiting}>
            自動音声案内で認証コードを再送する
            {this.state.waiting > 0 && ` — ${this.state.waiting}秒`}
          </a>
        );
      }
      default:
        return null;
    }
  }

  @autobind
  resetAuth() {
    // If Remember Me is enabled, the Login screen is skipped (auto-login).
    // If the consumer wants to go back to the Login screen, Remember Me should be disabled.
    robustStorage.setItem('paidy_remember_me', 'false');

    MixpanelHelpers.trackAction({
      customPath: 'Authentication (Payment Authorization)',
      actionName: MIXPANEL_ACTION_LINK_CLICK,
      actionItem: 'Change Credentials',
    });

    this.props.history.goBack();
  }

  @autobind
  renderHelpText() {
    if (this.state.authType !== authTypeMap.VOICE) {
      return null;
    }

    return (
      // warning message if voice pin is selected
      <>
        <div className={styles['voice-help-text']} id="voice_pin_warning">
          非通知を拒否されている場合、
          <br />
          解除する必要があります。
        </div>
        <p className={styles['help-text']}>
          ご不明な点がございましたら
          <br />
          Paidyフリーダイヤルまでご連絡ください。
          <span className={styles['customer-service-number']}>0120-971-918</span>
        </p>
      </>
    );
  }

  render() {
    const { phone } = this.props;
    const phoneToDisplay = getPhoneToDisplay(phone);

    return (
      <Frame helpType="otp">
        <div ref={this.containerRef} className={styles['scrollable-content']}>
          <span className={styles.title}>
            <b>{phoneToDisplay}</b>に届いた4桁の認証コードを入力してください
          </span>
          <div
            className={styles['inputs-wrapper']}
            ref={inputsWrapper => {
              // $FlowFixMe
              this.inputsWrapper = inputsWrapper;
            }}
          >
            {this.renderInputs(this.props.digits)}
          </div>
          {this.state.isWrongPincode && (
            <span className={styles['error-message']}>認証コードに誤りがあります</span>
          )}
          <div
            className={cx(
              styles['voice-prompt'],
              this.state.authType === authTypeMap.SMS && styles['fade-in']
            )}
          >
            {this.state.authType === authTypeMap.SMS && (
              <>
                <span>認証コードが届かない場合</span>
                <br />
              </>
            )}
            {this.renderActionLink()}
          </div>
          <div className={styles['auth-info']}>
            <div>
              <span>{this.props.email}</span>
              <hr />
              <span>{getPhoneToDisplay(this.props.phone)}</span>
            </div>
            <div>
              <a href="#" onClick={this.resetAuth}>
                編集
              </a>
            </div>
          </div>
          {this.state.authType === authTypeMap.VOICE && (
            <div className={styles['voice-prompt-instructions']}>
              <span>
                非通知を拒否されている場合、
                <br />
                解除する必要があります。
              </span>
              <span>
                ご不明な点がございましたら
                <br />
                Paidyフリーダイヤルまでご連絡ください。
              </span>
              <span>0120-971-918</span>
            </div>
          )}
        </div>
        {this.state.contentWidth > 0 && <SMSPrompt contentWidth={this.state.contentWidth} />}
      </Frame>
    );
  }
}

interface ParentComponentProps {
  contentWidth: number;
}

class SMSPrompt extends Component<ParentComponentProps> {
  state = {
    isReceivedSMS: false
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({
        isReceivedSMS: true
      })
    },1000)
  }

  render(){
    // this.props.contentWidth < 350 means Mobile View
    return this.state.isReceivedSMS && this.props.contentWidth < 350 ? (
      <div className={smsStyles['container']}>
        <p className={smsStyles['timestamp']}>今</p>
        <div className={smsStyles['content']}>
          <div className={smsStyles['icon']}>
            <img
              src={require('../../../assets/sms-icon.png')}
              width="31"
              height="31"
              alt="sms"
            />
          </div>
          <div className={smsStyles['message']}>
            <p>【ペイディ】認証コードは 1234です。認証コードをペイディの画面に入力すると、こちらの電話番号で決済が行われます。</p>
          </div>
        </div>
      </div>
    ): null;
  }
}

export default withRouter(OTPInputDemo);
