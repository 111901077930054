// @flow
import cx from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { updatePaymentPairs } from '../../redux/modules/payment';
import { useDispatch } from 'react-redux';
import Frame from '../Frame/FrameContainer';
import Option from '../ThreePay/Option';

import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW } from '../../utils/mixpanel';
import { hanToZen, toHalfWidth } from '../../utils';

import styles from './PlusUpgradePreselect.scss';

import type { ThreePayOption, UnavailableOption } from '../ThreePay/type';

type Props = {
  availableOptions: ThreePayOption[],
  selectedOption: ThreePayOption,
  onSelectOption: (option: ThreePayOption) => void,
  onClickNext: (option: ThreePayOption) => void,
  unavailableOptions: UnavailableOption[],
};

const PlusUpgradePreselect = ({
  availableOptions,
  selectedOption,
  onSelectOption,
  onClickNext,
  unavailableOptions,
}: Props) => {
  const TIME_INTERVAL = 60000; // 60000 milliseconds / 1 minute;
  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  const [maxWaitingTime, setMaxWaitingTime] = useState(Date.now() + 30 * 60 * 1000); // 30 minutes

  const npayOptions = availableOptions.filter(
    opt => opt.kind === '3pay' || opt.kind === '6pay' || opt.kind === '12pay'
  );
  const nlpOptions = unavailableOptions.filter(
    opt =>
      opt.reason === 'amount.over-limit' &&
      (opt.kind === '3pay' || opt.kind === '6pay' || opt.kind === '12pay')
  );

  const singlePayOption = availableOptions.find(opt => opt.kind === 'single_pay');
  const unavailableSinglePayOption = unavailableOptions.find(
    opt => opt.reason === 'amount.over-limit' && opt.kind === 'single_pay'
  );
  const npayEligible = npayOptions.length > 0;
  const nlpEligible = nlpOptions.length > 0;

  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    dispatch(updatePaymentPairs({ plusUpgradeWaitingTime: maxWaitingTime }));

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        'Screen type': 'Plus upgrade - pre upgrade',
        'Available Options': availableOptions.map(option => ({
          kind: option.kind,
          reason: option.reason,
        })),
        'Unavailable Options': unavailableOptions.map(option => ({
          kind: option.kind,
          reason: option.reason,
        })),
        'Pre-selected option': selectedOption.kind
      },
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    return () => {
      MixpanelHelpers.trackDuration({
        pathname,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        shouldEndTracker: true,
      });
    };
  }, []);

  useEffect(() => {
    // Called every minute
    const timeout = setTimeout(() => {
      setElapsedMinutes(elapsedMinutes => elapsedMinutes + 1);
    }, TIME_INTERVAL);

    // Clear timeout if elapsedMinutes is greater than or equal to MAX_TIME
    if (Date.now() >= maxWaitingTime) {
      clearTimeout(timeout);
      dispatch(updatePaymentPairs({ status: 'failed' }));
      history.push('/error/plus-upgrade.timeout');
    }

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timeout);
  }, [elapsedMinutes]);

  // ３・６・１２
  const getNpayOptionsString = (options = npayOptions) => {
    return options
      .map((option, index, arr) =>
        hanToZen(arr[arr.length - 1 - index].installments.length.toString())
      )
      .join('・');
  };

  return (
    <Frame hasCornerBackButton={false} hasFooter={false} helpType="plusUpgrade">
      <div className={styles['scrollable-content']} data-testid="scrollable-content">
        <div className={styles['info-section-message']}>
          <img src={require('../../assets/icon-lock.svg')} alt="icon-lock" width={48} height={48} />
          <span data-testid="plus-upgrade-message">
            安心・安全にお買い物を楽しんでいただくために、この後、再度ログインと本人確認（最短5分）をお願いしています。ご注文は本人確認後に確定します。
          </span>
        </div>
        <b className={styles.title}>お支払い回数を選ぶ</b>
        <span className={styles['npay-description']} data-testid="npay-description">
          <>
            分割手数料無料*で{toHalfWidth(getNpayOptionsString())}回あと払いに変更できます。
            <span className={cx(styles['secondary-text'], styles['gray'])}>
              *口座振替・銀行振込のみ無料
            </span>
          </>
        </span>
        {npayOptions.map(option => (
          <Option
            key={option.kind}
            isSelected={selectedOption && selectedOption.kind === option.kind}
            option={option}
            onSelectOption={onSelectOption}
          />
        ))}
        {singlePayOption && (
          <>
            <hr className={styles.separator} />
            <Option
              isSelected={selectedOption && selectedOption.kind === singlePayOption.kind}
              option={singlePayOption}
              onSelectOption={onSelectOption}
            />
          </>
        )}
        {nlpOptions.map(option => (
          <Option key={option.kind} option={{ ...option, disabled: true }} />
        ))}
        {unavailableSinglePayOption && (
          <>
            <hr className={styles.separator} />
            <Option option={{ ...unavailableSinglePayOption, disabled: true }} />
          </>
        )}
        {!singlePayOption && npayEligible && (
          <span className={cx(styles['sub-title'], styles.mb24)} data-testid="nlp-sub-title">
            {toHalfWidth(getNpayOptionsString(npayOptions))}回あと払いでの購入が可能です
          </span>
        )}
        {!nlpEligible && !unavailableSinglePayOption && (
          <span
            className={cx(styles['secondary-text'], styles.mb24, styles['gray'])}
            data-testid="nlp-description"
          >
            ※ {toHalfWidth(getNpayOptionsString(npayOptions))}
            回あと払いの利用を一度確定すると、再度支払い回数を変更することはできませんのでご注意ください。
          </span>
        )}
        {singlePayOption && nlpEligible && (
          <>
            <span className={cx(styles['sub-title'], styles.mb24)} data-testid="nlp-sub-title">
              一括払いでの購入が可能です
            </span>
            <span
              className={cx(styles['npay-description'], styles['secondary-text'])}
              data-testid="nlp-description"
            >
              {`${toHalfWidth(
                getNpayOptionsString(nlpOptions)
              )}回あと払いは、翌月以降のご利用可能額が不足しているため、選択できません。詳しくはペイディアプリをご確認ください。`}
            </span>
          </>
        )}
        {npayEligible && nlpEligible && (
          <span
            className={cx(styles['npay-description'], styles['secondary-text'])}
            data-testid="nlp-description"
          >
            {`${toHalfWidth(
              getNpayOptionsString(nlpOptions)
            )}回あと払いは、翌月以降のご利用可能額が不足しているため、選択できません。`}
          </span>
        )}
        {!singlePayOption && npayEligible && (
          <>
            <span
              className={cx(styles['npay-description'], styles['secondary-text'])}
              data-testid="nlp-copyright"
            >
              一括払いは、今月のご利用可能額を下記の方法で調整すると、利用可能になる場合があります。
            </span>
            <ul className={styles.ul}>
              <li className={cx(styles['npay-description'], styles['secondary-text'])}>
                ご請求金額をすぐ払い機能で支払う
              </li>
              <li className={cx(styles['npay-description'], styles['secondary-text'])}>
                一括払いのご利用を3・6・12回あと払いに変更する
              </li>
            </ul>
            <span className={cx(styles['npay-description'], styles['secondary-text'])}>
              詳しくはペイディアプリをご確認ください。
            </span>
          </>
        )}
      </div>
      <div className={styles.controls}>
        <button
          id="btn-next"
          data-testid="btn-next"
          className={styles.button}
          onClick={() => onClickNext(selectedOption)}
          autoFocus
        >
          次へ
        </button>
      </div>
    </Frame>
  );
};

export default PlusUpgradePreselect;
